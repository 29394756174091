import React, { useMemo, useState } from 'react';
import './SkuDetailsPopup.css';
import { useTranslation } from 'react-i18next';

const SkuDetailsPopup = ({ card, onClose, calculatePrice, userSettings, exchangeRates }) => {
  const [refreshKey, setRefreshKey] = useState(0);
  const { t } = useTranslation();

  const formatPrice = (price) => {
    return isNaN(price) ? '0.00' : price.toFixed(2);
  };

  const formatPercentage = (percentage) => {
    if (isNaN(percentage) || percentage === null) return '0';
    const formattedPercentage = (percentage * 100).toFixed(0);
    return formattedPercentage;
  };

  const conditionOrder = ['NM', 'LP', 'MP', 'HP', 'DM'];

  const currencySymbols = {
    USD: '$', EUR: '€', GBP: '£', JPY: '¥', CAD: 'C$', AUD: 'A$', CHF: 'CHF', CNY: '¥', SEK: 'kr', NZD: 'NZ$',
    MXN: 'Mex$', SGD: 'S$', HKD: 'HK$', NOK: 'kr', KRW: '₩', TRY: '₺', RUB: '₽', INR: '₹', BRL: 'R$', ZAR: 'R'
  };

  const currencySymbol = currencySymbols[userSettings?.currency] || '$';

  const convertPrice = (price, fromCurrency = 'USD', toCurrency = userSettings?.currency) => {
    if (fromCurrency === toCurrency) return price;
    const exchangeRate = exchangeRates[toCurrency] || 1;
    return price * exchangeRate;
  };

  const calculateSteppedPrice = (condition, nmPrice) => {
    switch (condition) {
      case 'NM': return nmPrice;
      case 'LP': return nmPrice * 0.8;
      case 'MP': return nmPrice * 0.64;
      case 'HP': return nmPrice * 0.512;
      case 'DM': return nmPrice * 0.4096;
      default: return nmPrice;
    }
  };

  const groupedSkus = useMemo(() => {
    const grouped = {};
    card.skus
      .filter(sku => sku.langAbbr === 'EN')
      .forEach(sku => {
        if (!grouped[sku.printingName]) {
          grouped[sku.printingName] = {};
        }
        grouped[sku.printingName][sku.condAbbr] = sku;
      });

    return grouped;
  }, [card.skus, refreshKey, userSettings, exchangeRates]);

  const isValidPrice = (price) => {
    return price !== null && price !== undefined && !isNaN(price) && price > 0;
  };

  const chooseBasePrice = (tcgPlayerPrice, steppedPrice) => {
    const isTcgValid = isValidPrice(tcgPlayerPrice);
    const isSteppedValid = isValidPrice(steppedPrice);

    if (isTcgValid && !isSteppedValid) {
      return { price: tcgPlayerPrice, source: 'TCG' };
    } else if (!isTcgValid && isSteppedValid) {
      return { price: steppedPrice, source: 'Stepped' };
    } else if (isTcgValid && isSteppedValid) {
      return tcgPlayerPrice < steppedPrice
        ? { price: tcgPlayerPrice, source: 'TCG' }
        : { price: steppedPrice, source: 'Stepped' };
    } else {
      return { price: 0, source: 'N/A' };
    }
  };

  // Check if any SKU has originalCurrency as EUR to determine if using Cardmarket
  const isCardmarket = useMemo(() => {
    return card.skus.some(sku => sku.originalCurrency === 'EUR');
  }, [card.skus]);

  return (
    <div className="sku-details-popup-overlay">
      <div className="sku-details-popup">
        <div className="popup-header">
          <h2>{card.name}</h2>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>
        <div className="popup-content">
          <div className="card-info">
            <img src={card.image} alt={card.name} className="card-image" />
            <div className="card-details">
              <p><strong>{t('Set')}:</strong> {card.expansionName}</p>
              <p><strong>{t('Rarity')}:</strong> {card.rarity}</p>
              <p><strong>ID:</strong> {isCardmarket ? `Cardmarket: ${card.idProduct}` : `TCGplayer: ${card.productId}`}</p>
            </div>
          </div>
          <div className="sku-table-container">
            {Object.entries(groupedSkus).map(([printing, skus]) => (
              <div key={printing} className="printing-group">
                <h3>{printing}</h3>
                <table className="sku-table">
                  <thead>
                    <tr>
                      <th>{t('Condition')}</th>
                      <th>{t('SKU ID')}</th>
                      <th>{isCardmarket ? t('Cardmarket Price') : t('TCGplayer Price')}</th>
                      <th>{t('Stepped Price')}</th>
                      <th>{t('Chosen Base')}</th>
                      <th>{t('Cash Price')} (%)</th>
                      <th>{t('Credit Price')} (%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {conditionOrder.map(condition => {
                      const sku = skus[condition];
                      const nmSku = skus['NM'];
                      const nmPrice = nmSku ? convertPrice(parseFloat(nmSku.lowPrice)) : 0;
                      const tcgPlayerPrice = sku ? convertPrice(parseFloat(sku.lowPrice)) : null;
                      const steppedPrice = calculateSteppedPrice(condition, nmPrice);
                      const { price: basePrice, source } = chooseBasePrice(tcgPlayerPrice, steppedPrice);
                      let { cashPrice, creditPrice, cashPercentage, creditPercentage } = calculatePrice(card, condition, printing);
                      
                      // Format market price display based on source
                      const marketPriceDisplay = (() => {
                        if (!sku) return 'N/A';
                        if (isCardmarket && sku.eurPrice) {
                          return `€${formatPrice(sku.eurPrice)} / ${currencySymbol}${formatPrice(tcgPlayerPrice)}`;
                        }
                        return isValidPrice(tcgPlayerPrice) ? `${currencySymbol}${formatPrice(tcgPlayerPrice)}` : 'N/A';
                      })();

                      return (
                        <tr key={condition}>
                          <td>{condition}</td>
                          <td>{sku ? sku.skuId : 'N/A'}</td>
                          <td>{marketPriceDisplay}</td>
                          <td>{`${currencySymbol}${formatPrice(steppedPrice)}`}</td>
                          <td>{`${currencySymbol}${formatPrice(basePrice)} (${source})`}</td>
                          <td>{currencySymbol}{formatPrice(cashPrice)} <span className="percentage">({formatPercentage(cashPercentage)}%)</span></td>
                          <td>{currencySymbol}{formatPrice(creditPrice)} <span className="percentage">({formatPercentage(creditPercentage)}%)</span></td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkuDetailsPopup;
