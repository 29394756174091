import React, { useState, useEffect, useCallback, useRef } from 'react';
import './CardList.css';
import Popup from './Popup';
import SkuDetailsPopup from './SkuDetailsPopup';
import PriceInquiryForm from './PriceInquiryForm';
import { useTranslation } from 'react-i18next';
import { BACKEND_URL } from '../config';

const CardList = ({ cards, addToCart, allowedConditions, floorPrice, bulkSettings, userSettings, excludedSku, calculatePrice, exchangeRates }) => {
  const { t } = useTranslation();
  const [selectedConditions, setSelectedConditions] = useState({});
  const [selectedPrintings, setSelectedPrintings] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [showSkuDetailsPopup, setShowSkuDetailsPopup] = useState(false);
  const [showPriceInquiryForm, setShowPriceInquiryForm] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [prices, setPrices] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const isDKeyPressed = useRef(false);
  const [showBulkItems, setShowBulkItems] = useState(() => {
    const saved = localStorage.getItem('showBulkItems');
    return saved !== null ? JSON.parse(saved) : true;
  });
  const [isTableView, setIsTableView] = useState(() => {
    const saved = localStorage.getItem('isTableView');
    return saved !== null ? JSON.parse(saved) : false;
  });

  const currencySymbols = {
    USD: '$', EUR: '€', GBP: '£', JPY: '¥', CAD: 'C$', AUD: 'A$', CHF: 'CHF', CNY: '¥', SEK: 'kr', NZD: 'NZ$',
    MXN: 'Mex$', SGD: 'S$', HKD: 'HK$', NOK: 'kr', KRW: '₩', TRY: '₺', RUB: '₽', INR: '₹', BRL: 'R$', ZAR: 'R'
  };

  useEffect(() => {
    const fetchPrices = async () => {
      const newPrices = {};
      for (const card of cards) {
        const cardConditions = [...new Set(card.skus
          .map(sku => sku.condAbbr)
          .filter(cond => allowedConditions.includes(cond)))];
        const uniquePrintings = [...new Set(card.skus.map(sku => sku.printingName))];
        const condition = selectedConditions[card._id] || cardConditions[0] || '';
        const printing = selectedPrintings[card._id] || uniquePrintings[0] || '';
        const price = calculatePrice(card, condition, printing);
        newPrices[card._id] = price;
      }
      setPrices(newPrices);
      setIsLoading(false);
    };

    fetchPrices();
  }, [cards, selectedConditions, selectedPrintings, allowedConditions, calculatePrice]);

  const handleConditionChange = useCallback((cardId, newCondition) => {
    setSelectedConditions(prev => ({ ...prev, [cardId]: newCondition }));
  }, []);

  const handlePrintingChange = useCallback((cardId, newPrinting) => {
    setSelectedPrintings(prev => ({ ...prev, [cardId]: newPrinting }));
  }, []);

  const handleAddToCart = useCallback((card, isCash) => {
    const condition = selectedConditions[card._id] || card.skus[0]?.condAbbr || '';
    const printing = selectedPrintings[card._id] || card.skus[0]?.printingName || '';
    const quantity = 1;
    const sku = card.skus.find(s => s.condAbbr === condition && s.printingName === printing);
    
    const cardPrice = prices[card._id];
    if (!cardPrice) {
      console.error('Price not found for card:', card._id);
      return;
    }

    const basePrice = cardPrice.basePrice;
    const offeredPrice = isCash ? cardPrice.cashPrice : cardPrice.creditPrice;

    console.log('Adding to cart:', {
      name: card.name,
      condition,
      printing,
      basePrice,
      offeredPrice,
      isCash,
      skuId: sku?.skuId
    });

    addToCart(card, condition, printing, quantity, sku?.skuId, isCash);
  }, [selectedConditions, selectedPrintings, addToCart, prices]);

  const handleContactForPrice = useCallback((card) => {
    setSelectedCard(card);
    setShowPriceInquiryForm(true);
  }, []);

  const closePopup = useCallback(() => {
    setShowPopup(false);
  }, []);

  const handleOpenSkuDetails = useCallback((card) => {
    if (isDKeyPressed.current) {
      setSelectedCard(card);
      setShowSkuDetailsPopup(true);
    }
  }, []);

  const closeSkuDetailsPopup = useCallback(() => {
    setShowSkuDetailsPopup(false);
    setSelectedCard(null);
  }, []);

  const closePriceInquiryForm = useCallback(() => {
    setShowPriceInquiryForm(false);
    setSelectedCard(null);
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'd' || e.key === 'D') {
        isDKeyPressed.current = true;
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === 'd' || e.key === 'D') {
        isDKeyPressed.current = false;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const isExcluded = useCallback((card) => {
    console.log('Checking exclusion for:', {
      cardName: card.name,
      productId: card.productId,
      excludedSku,
      isExcluded: excludedSku.includes(card.productId.toString())
    });
    return excludedSku.includes(card.productId.toString());
  }, [excludedSku]);

  const formatPrice = (price) => {
    return isNaN(price) ? '0.00' : price.toFixed(2);
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const toggleBulkItems = () => {
    const newValue = !showBulkItems;
    setShowBulkItems(newValue);
    localStorage.setItem('showBulkItems', JSON.stringify(newValue));
  };

  const toggleTableView = () => {
    const newValue = !isTableView;
    setIsTableView(newValue);
    localStorage.setItem('isTableView', JSON.stringify(newValue));
  };

  if (isLoading) {
    return (
      <div className="loading-screen">
        <p>{t('Just fetching you the best prices...')}</p>
      </div>
    );
  }

  const filteredCards = cards.filter(card => {
    const { isBulk } = prices[card._id] || { isBulk: false };
    return showBulkItems || !isBulk;
  });

  const renderCardView = (card) => {
    const cardConditions = [...new Set(card.skus
      .map(sku => sku.condAbbr)
      .filter(cond => allowedConditions.includes(cond)))];
    const uniquePrintings = [...new Set(card.skus.map(sku => sku.printingName))];
    const condition = selectedConditions[card._id] || cardConditions[0] || '';
    const printing = selectedPrintings[card._id] || uniquePrintings[0] || '';
    const { cashPrice, creditPrice, isBulk, timestamp, contactForPrice } = prices[card._id] || { cashPrice: 0, creditPrice: 0, isBulk: false, timestamp: null, contactForPrice: false };
    const excluded = isExcluded(card); // Updated to only pass card
    const noPriceAvailable = cashPrice === 0 && creditPrice === 0 && !isBulk;

    return (
      <div key={card._id} className="card">
        <img src={card.image} alt={card.name} className="card-image" />
        <div className="card-info">
          <h3 className="card-name">{card.name}</h3>
          <p className="card-expansion">{card.expansionName || t('Expansion not available')}</p>
          <div className="card-options">
            <select 
              className="condition-select"
              value={condition}
              onChange={(e) => handleConditionChange(card._id, e.target.value)}
            >
              {cardConditions.map((cond, index) => (
                <option key={index} value={cond}>{cond}</option>
              ))}
            </select>
            <select 
              className="printing-select"
              value={printing}
              onChange={(e) => handlePrintingChange(card._id, e.target.value)}
            >
              {uniquePrintings.map((printing, index) => (
                <option key={index} value={printing}>{printing}</option>
              ))}
            </select>
          </div>
          <div className="card-actions">
            {contactForPrice ? (
              <button 
                className="add-to-cart-button contact-for-price amber-bg"
                onClick={() => handleContactForPrice(card)}
              >
                {t('Please Contact for Price')}
              </button>
            ) : excluded || noPriceAvailable ? (
              <button className="add-to-cart-button not-buying" disabled>
                {t('Not Buying')}
              </button>
            ) : isBulk ? (
              <button className="add-to-cart-button bulk" onClick={() => setShowPopup(true)}>
                {t('Bulk Item')}
              </button>
            ) : (
              <>
                {creditPrice > 0 && (
                  <button 
                    className="add-to-cart-button credit"
                    onClick={() => handleAddToCart(card, false)}
                  >
                    {t('Credit')} {currencySymbols[userSettings.currency] || '$'}{formatPrice(creditPrice)}
                  </button>
                )}
                {cashPrice > 0 ? (
                  <button 
                    className="add-to-cart-button cash"
                    onClick={() => handleAddToCart(card, true)}
                  >
                    {t('Cash')} {currencySymbols[userSettings.currency] || '$'}{formatPrice(cashPrice)}
                  </button>
                ) : (
                  <button className="add-to-cart-button not-buying" disabled>
                    {t('Not Buying Cash')}
                  </button>
                )}
              </>
            )}
          </div>
          {timestamp && (
            <p className="price-timestamp" onClick={() => handleOpenSkuDetails(card)}>
              {t('Price fetched at')}: {formatTimestamp(timestamp)}
            </p>
          )}
        </div>
      </div>
    );
  };

  const renderTableView = () => {
    return (
      <table className="card-table">
        <thead>
          <tr>
            <th>{t('Name')}</th>
            <th>{t('Expansion')}</th>
            <th>{t('Condition')}</th>
            <th>{t('Printing')}</th>
            <th>{t('Credit Price')}</th>
            <th>{t('Cash Price')}</th>
            <th>{t('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {filteredCards.map((card) => {
            const cardConditions = [...new Set(card.skus
              .map(sku => sku.condAbbr)
              .filter(cond => allowedConditions.includes(cond)))];
            const uniquePrintings = [...new Set(card.skus.map(sku => sku.printingName))];
            const condition = selectedConditions[card._id] || cardConditions[0] || '';
            const printing = selectedPrintings[card._id] || uniquePrintings[0] || '';
            const { cashPrice, creditPrice, isBulk, contactForPrice } = prices[card._id] || { cashPrice: 0, creditPrice: 0, isBulk: false, contactForPrice: false };
            const excluded = isExcluded(card); // Updated to only pass card
            const noPriceAvailable = cashPrice === 0 && creditPrice === 0 && !isBulk;

            return (
              <tr key={card._id}>
                <td>{card.name}</td>
                <td>{card.expansionName || t('Expansion not available')}</td>
                <td>
                  <select 
                    value={condition}
                    onChange={(e) => handleConditionChange(card._id, e.target.value)}
                  >
                    {cardConditions.map((cond, index) => (
                      <option key={index} value={cond}>{cond}</option>
                    ))}
                  </select>
                </td>
                <td>
                  <select 
                    value={printing}
                    onChange={(e) => handlePrintingChange(card._id, e.target.value)}
                  >
                    {uniquePrintings.map((printing, index) => (
                      <option key={index} value={printing}>{printing}</option>
                    ))}
                  </select>
                </td>
                <td>{currencySymbols[userSettings.currency] || '$'}{formatPrice(creditPrice)}</td>
                <td>{currencySymbols[userSettings.currency] || '$'}{formatPrice(cashPrice)}</td>
                <td>
                  {contactForPrice ? (
                    <button 
                      className="add-to-cart-button contact-for-price amber-bg"
                      onClick={() => handleContactForPrice(card)}
                    >
                      {t('Contact for Price')}
                    </button>
                  ) : excluded || noPriceAvailable ? (
                    <button className="add-to-cart-button not-buying" disabled>
                      {t('Not Buying')}
                    </button>
                  ) : isBulk ? (
                    <button className="add-to-cart-button bulk" onClick={() => setShowPopup(true)}>
                      {t('Bulk Item')}
                    </button>
                  ) : (
                    <>
                      <button 
                        className="add-to-cart-button credit"
                        onClick={() => handleAddToCart(card, false)}
                      >
                        {t('Add Credit')}
                      </button>
                      <button 
                        className="add-to-cart-button cash"
                        onClick={() => handleAddToCart(card, true)}
                      >
                        {t('Add Cash')}
                      </button>
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <div className="card-list-container">
      <div className="toggle-row">
        <div className="bulk-toggle">
          <label className="switch">
            <input
              type="checkbox"
              checked={showBulkItems}
              onChange={toggleBulkItems}
            />
            <span className="slider round"></span>
          </label>
          <span className="toggle-label">{t('Bulk')}</span>
        </div>
        <div className="view-toggle">
          <label className="switch">
            <input
              type="checkbox"
              checked={isTableView}
              onChange={toggleTableView}
            />
            <span className="slider round"></span>
          </label>
          <span className="toggle-label">{t('Table View')}</span>
        </div>
      </div>
      <div className={`card-list ${isTableView ? 'table-view' : ''}`}>
        {isTableView ? renderTableView() : filteredCards.map(renderCardView)}
      </div>
      {showPopup && <Popup content={bulkSettings} onClose={closePopup} />}
      {showSkuDetailsPopup && selectedCard && (
        <SkuDetailsPopup
          card={selectedCard}
          onClose={closeSkuDetailsPopup}
          calculatePrice={calculatePrice}
          userSettings={userSettings}
          exchangeRates={exchangeRates}
        />
      )}
      {showPriceInquiryForm && selectedCard && (
        <PriceInquiryForm
          card={selectedCard}
          condition={selectedConditions[selectedCard._id] || selectedCard.skus[0]?.condAbbr || ''}
          printing={selectedPrintings[selectedCard._id] || selectedCard.skus[0]?.printingName || ''}
          onClose={closePriceInquiryForm}
          userSettings={userSettings}
        />
      )}
    </div>
  );
};

export default React.memo(CardList);
